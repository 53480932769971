import { ReactElement } from "react";
import { graphql } from "gatsby";
import { showModal } from "../../hooks/UseModal";
import { CookiesSettingsModal } from "../../components/Shared/Modals/CookiesSettingsModal";
import { cookiesStorageItemKey } from "../../components/Layout/CookiesConsent";
import isBrowser from "../../shared/IsBrowser";
import * as styles from "./privacyPolicy.module.scss";
import Helmet from "../../components/Helmet";

interface ISpecialOffer {
    data: GatsbyTypes.PrivacyPolicyPageQuery;
}

export default function PrivacyPolicy({ data }: ISpecialOffer): ReactElement {
    const settings = (data.strapi.privacyPolicy?.settings as GatsbyTypes.strapi_ComponentPrivacyPolicyCookieSetting[]) ?? [];
    const userAcceptedCookies = isBrowser() ? localStorage.getItem(cookiesStorageItemKey) !== null : false;

    function handleSettingsClick() {
        showModal(CookiesSettingsModal, { title: data.strapi.privacyPolicy?.settingsTitle ?? "", settings: settings, acceptButtonText: "Confirm", cancelButtonText: "Cancel" });
    }

    return (
        <section className={styles.privacyPolicePage}>
            <Helmet title={data.strapi.privacyPolicy?.base?.heading} description={""} />
            <div className="headerContainer">
                <div className="mainBox">
                    <h1>{data.strapi.privacyPolicy?.base?.heading}</h1>
                </div>
            </div>

            <div className={styles.wrapper}>
                <div className={styles.inner}>
                    <div dangerouslySetInnerHTML={{ __html: data.strapi.privacyPolicy?.base?.paragraph ?? "" }}></div>
                    {userAcceptedCookies && settings.length > 0 && (
                        <button onClick={handleSettingsClick} className={styles.cookieSettingsBtn}>
                            {data.strapi.privacyPolicy?.settingsTitle}
                        </button>
                    )}
                </div>
            </div>
        </section>
    );
}

export const query = graphql`
    query PrivacyPolicyPage {
        strapi {
            privacyPolicy {
                base {
                    heading
                    paragraph
                }
                settingsTitle
                settings {
                    id
                    name
                    base {
                        heading
                        paragraph
                    }
                    required
                }
            }
        }
    }
`;
