import { ReactElement, useState, useEffect } from "react";
import { ModalContentProps } from "../../../../hooks/UseModal";
import { cookiesStorageItemKey } from "../../../Layout/CookiesConsent";
import SettingItem from "../../../Layout/CookiesConsent/SettingItem";
import { ModalFrame } from "../ModalFrame";
import { closeIcon } from "../../../Icons";
import * as styles from "./CookiesSettingsModal.module.scss";

export interface CookiesSettingsModalProps {
    title: string;
    settings: GatsbyTypes.strapi_ComponentPrivacyPolicyCookieSetting[];
    acceptButtonText: string;
    cancelButtonText: string;
}

export function CookiesSettingsModal(props: ModalContentProps<CookiesSettingsModalProps, boolean>): ReactElement {
    const [cookiesAcceptance, setCookiesAcceptance] = useState<{ [name: string]: boolean }>();

    useEffect(() => {
        const storageItem = localStorage.getItem(cookiesStorageItemKey);
        const newCookiesAcceptance = Object.assign({}, ...props.settings.map(x => ({ [x?.name ?? ""]: true })), storageItem ? JSON.parse(storageItem) : {});
        setCookiesAcceptance(newCookiesAcceptance);
    }, []);

    function handleCheckedChange(name: string, checked: boolean) {
        setCookiesAcceptance({ ...cookiesAcceptance, [name]: checked });
    }

    function handleAcceptClick() {
        localStorage.setItem(cookiesStorageItemKey, JSON.stringify(cookiesAcceptance));
        props.onClose(true);
    }

    return (
        <ModalFrame useCard={false}>
            <div className={styles.modalContent}>
                <h1>{props.title}</h1>

                {cookiesAcceptance &&
                    props.settings?.map(x => (
                        <SettingItem
                            key={x?.id}
                            setting={x as GatsbyTypes.strapi_ComponentPrivacyPolicyCookieSetting}
                            initialValue={cookiesAcceptance[x?.name ?? ""]}
                            onCheckedChange={handleCheckedChange}
                        />
                    ))}

                <div className={styles.modalActions}>
                    <button onClick={handleAcceptClick} className={styles.confirmButton}>
                        {props.acceptButtonText}
                    </button>
                    <button onClick={() => props.onClose(true)} className={styles.cancelButton}>
                        {closeIcon}
                    </button>
                </div>
            </div>
        </ModalFrame>
    );
}
